import './resultRender.css'
import React from 'react';
import Rsvp from './rsvp';

function handleSubmit() {
    //todo handle submit of edited claims
}

function ClaimQuestions({ claim, editable, editedClaim, handleChange }) {
    return (
      <div>
        <h2>Questions</h2>
        {claim.questions.map((question, index) => (
          <div key={index}>
            <h3>Question {index + 1}</h3>
            <p>{question.question}</p>
  
            <h4>Answers</h4>
            {question.answers.map((answer, answerIndex) => (
              <div key={answerIndex}>
                <p>
                  <strong>Answer:</strong> {answer.answer}
                </p>
                <p>
                  <strong>Answer Type:</strong> {answer.answer_type}
                </p>
                <p>
                  <strong>Source URL:</strong>{' '}
                  {answer.source_url ? (
                    <a href={answer.source_url} target="_blank" rel="noopener noreferrer">
                      {answer.source_url}
                    </a>
                  ) : (
                    'N/A'
                  )}
                </p>
                  {/* <strong>Source URL:</strong>{' '}
                  {answer.source_url ? (
                    <iframe src="www.example.com" style="width:500px; height: 300px;"></iframe>
                  ) : (
                    'N/A'
                  )} */}
                <p>
                  <strong>Source Medium:</strong> {answer.source_medium}
                </p>
                {answer.answer_type === 'Boolean' && (
                  <p>
                    <strong>Boolean Explanation:</strong> {answer.boolean_explanation}
                  </p>
                )}
                <p>
                  <strong>Cached Source URL:</strong>{' '}
                  {answer.cached_source_url ? (
                    <a href={answer.cached_source_url} target="_blank" rel="noopener noreferrer">
                      {answer.cached_source_url}
                    </a>
                  ) : (
                    'N/A'
                  )}
                </p>
                <Rsvp source={answer.cached_source_url}/>
                {/* <iframe
                id="inlineFrameExample"
                title="Inline Frame Example"
                className="iFramefullWidthHeight"
                onLoad={this.hideSpinner}
                src={answer.cached_source_url}>
              </iframe> */}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

function ClaimDetails({ claim, editable, editedClaim, handleChange }) {
    return (
      <div>
        <h2>Details</h2>
        <ul>
          <li>
            <strong>Required Reannotation:</strong>{' '}
            {claim.required_reannotation ? 'Yes' : 'No'}
          </li>
          <li>
            <strong>Label:</strong> {claim.label}
          </li>
          <li>
            <strong>Justification:</strong> {claim.justification}
          </li>
          <li>
            <strong>Claim Date:</strong> {claim.claim_date}
          </li>
          <li>
            <strong>Speaker:</strong> {claim.speaker}
          </li>
          <li>
            <strong>Original Claim URL:</strong>{' '}
            {claim.original_claim_url ? (
              <a href={claim.original_claim_url} target="_blank" rel="noopener noreferrer">
                {claim.original_claim_url}
              </a>
            ) : (
              'N/A'
            )}
          </li>
          <li>
            <strong>Fact Checking Article:</strong>{' '}
            {claim.fact_checking_article ? (
              <a href={claim.fact_checking_article} target="_blank" rel="noopener noreferrer">
                {claim.fact_checking_article}
              </a>
            ) : (
              'N/A'
            )}
          </li>
          <li>
            <strong>Reporting Source:</strong> {claim.reporting_source}
          </li>
          <li>
            <strong>Location ISO Code:</strong> {claim.location_ISO_code}
          </li>
          <li>
            <strong>Claim Types:</strong> {claim.claim_types.join(', ')}
          </li>
          <li>
            <strong>Fact Checking Strategies:</strong>{' '}
            {editable ? (
            <input
              type="text"
              name="fact_checking_strategies"
              value={editedClaim.fact_checking_strategies.join(', ')}
              onChange={handleChange}
              required
            />
          ) : (
            claim.fact_checking_strategies.join(', ')
          )}
          </li>
        </ul>
      </div>
    );
  }


/**
 * Render the result verdict and evidence to page elements
 * @param {*} props 
 * @returns page elements
 */
export const RenderResultAVERITEC = (props) => {
    let claim = props.result;
    let waiting = props.waiting;

    //todo: Add way to edit claim
    let editable = false; 
    let editedClaim = false;
    let handleChange = false;

    const iframeRef = React.useRef(null)

    React.useEffect(() => {
      if (iframeRef && iframeRef.current) {
        const apiResponse = `
          <html>
          <body style="width: 100vw; height: 100vh; background: lime">
            Hello
          </body>
          </html>
        `;
        const doc = iframeRef.current.contentWindow.document;
        doc.open();
        doc.write(apiResponse);
        doc.close();
      }
    }, [])


    if ((props.result === "" && !waiting) || (props.result != "" && 'table_evidence' in props.result)){ // Reset if swapping between datasets
        return (
            <div></div>
        )
    }
    else if (waiting){
        //todo: possible add a counter
        return (
            <div>Waiting for result...</div>
        )
    }
    else {
        return (
            <div>
              <h2>Claim</h2>
              <p>{claim.claim}</p>
        
              <ClaimDetails
                claim={claim}
                editable={editable}
                editedClaim={editedClaim}
                handleChange={handleChange}
              />
        
              <ClaimQuestions
                claim={claim}
                editable={editable}
                editedClaim={editedClaim}
                handleChange={handleChange}
              />
        
              {editable && (
                <button type="submit" onClick={handleSubmit}>
                  Save Changes
                </button>
              )}
            </div>
          );
        }
}