import './App.css';
import React, { useState } from 'react';
import { RenderResultFEVEROUS } from './result/resultRender_feverous.js';
import { RenderResultAVERITEC } from './result/resultRender_averitec.js';
import {searchResult} from './result/apis.js'

function App() {
  const [inputClaim,setInputClaim] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [result, setResult] = useState("");
  const [selectedResult, setSelectedResult] = useState('FEVEROUS'); // Initialize with the default value "FEVEROUS"
  const [jumpToId, setJumpToId] = useState("");
  // when submit, sending the claim to GPU and wait for result to present

  const handleResultChange = (event) => {
    setSelectedResult(event.target.value);
  };


  async function handleNextSubmit (e){
    e.preventDefault();

      setWaiting(true);
      const checkResult = await searchResult("next", selectedResult);
      setWaiting(false);
      setResult(checkResult);
  }

  async function handlePreviousSubmit (e){
    e.preventDefault();

      setWaiting(true);
      const checkResult = await searchResult("previous", selectedResult);
      setWaiting(false);
      setResult(checkResult);
  }


  async function handleJumpToId() {
    if (jumpToId) {
      setWaiting(true);
      const checkResult = await searchResult("jump", selectedResult, jumpToId);
      setWaiting(false);
      setResult(checkResult);
    }
  }

  return (
    <>
    <div>
        {/* Dropdown menu to select the result type */}
        <select value={selectedResult} onChange={handleResultChange}>
          <option value="FEVEROUS">FEVEROUS</option>
          <option value="AVERITEC">AVERITEC</option>
        </select>
    </div>

    <div className="newClaim-box">
    <form className="newClaimForm" onSubmit={handlePreviousSubmit}>
        <input className="submit-button" type="submit" value="Previous sample." />
      </form>
      <form className="newClaimForm" onSubmit={handleNextSubmit}>
        <input className="submit-button" type="submit" value="Next sample." />
      </form>
    </div>
    <div>
    <input
      type="text"
      placeholder="Enter ID to Jump"
      value={jumpToId}
      onChange={(e) => setJumpToId(e.target.value)}
    />
    <button onClick={handleJumpToId}>Jump</button>
    </div>

      <div className='result'>
        {/* Render the corresponding component based on the selected option */}
        {selectedResult === 'FEVEROUS' ? (
          <RenderResultFEVEROUS waiting={waiting} result={result} claim={inputClaim} />
        ) : (
          <RenderResultAVERITEC waiting={waiting} result={result} claim={inputClaim} />
        )}
      </div>

    {/* <div className='result'>
      <RenderResultFEVEROUS waiting = {waiting} result={result} claim={inputClaim}/>
    </div> */}

    </>
  );
}

export default App;

