import React from 'react';
import Spinner from 'react-spinkit'; // Assuming you're using a spinner library
import './resultRender.css'

class Rsvp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  hideSpinner = () => {
    this.setState({
      loading: false
    });
  };

//   render() {
//     const { source } = this.props;
//     return (
//         <>
//             { this.state.loading ? <Spinner name="circle" />: <></> }
//             <iframe
//                 src={source}
//                 className="rsvp-wrapper"
//                 onLoad={this.hideSpinner}
//             />
//         </>
//     )
//   }
render() {
    const { source } = this.props;
    return (
      <div className="container iframe-main">
        <iframe
          src={source}
          onLoad={this.hideSpinner}
          className="iframe"
        />
        {this.state.loading && (
          <div className="spinner-container">
            <Spinner name="circle" />
          </div>
        )}
      </div>
    );
  }

}

export default Rsvp;

    // return (
    //   <div container rsvp-wrapper>
    //     {this.state.loading ? (
    //       <Spinner
    //         className="loading text-center"
    //         name="circle"
    //         color="white"
    //         fadeIn="none"
    //       />
    //     ) : null}
    //     <iframe
    //       src={source}
    //       width="100%"
    //       height="700"
    //       onLoad={this.hideSpinner}
    //       frameBorder="0"
    //       marginHeight="0"
    //       marginWidth="0"
    //     //   onLoad={this.hideSpinner}
    //     />
    //   </div>
    // );