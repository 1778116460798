// const ENGINE_ADDRESS = "http://localhost:5100/feverous_demo";
import { SERVER_ADDRESS } from "./server_address.js"
// const ENGINE_ADDRESS = "http://localhost:8000/feverous_demo" //"https://127.0.0.1:5100";  // Public IP address to be used when connected to fever.ai or sth 2.56.97.247
// const ENGINE_ADDRESS = "https://raly.me/feverous_demo"
// const ENGINE_ADDRESS = "http://127.0.0.1:5200/feverous_demo"
//const ENGINE_ADDRESS = "https://2.56.97.247:8000/feverous_demo";

/**
 * The function that connects backend engine API.
 * @returns {JSON} checkresult and evidence
 */
export const searchResult = async (mode, dataset, jumpToId) => {
    let result;
    // todo: assertion on inputclaim for protection
    await fetch(SERVER_ADDRESS, {
        method: 'POST',
        body: JSON.stringify({
            'mode': mode,
            'dataset': dataset,
            "jump_id": jumpToId,
        }),
        headers: {
            "Content-Type": "application/json",
            "charset": "utf-8",
            "Accept": "application/json", // "text/plain", //
        },
    })
    .then(res => res.json())
    .then(data => result = data)
    .catch(err => console.error(err))

    return result
}