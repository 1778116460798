import './resultRender.css'
import React from 'react';
const GLOBLE_WIKI_ADDRESS = 'https://en.wikipedia.org/wiki/'


const sanitiseLinks = (evidence_sentence) => {
    let position_title_sep = evidence_sentence.search(/\|\|/);
    let sentence_with_link_format = position_title_sep === -1? evidence_sentence: evidence_sentence.substring(0,position_title_sep);
    // let evidence_page_title = evidence_sentence.substring(position_title_sep+2);

    let ret_elements = []
    while (true){
        let link_start = sentence_with_link_format.search(/\[\[/);
        if (link_start === -1){
             ret_elements.push(sentence_with_link_format);
            break
        }
        let link_end = sentence_with_link_format.search(/\]\]/);
        let link_sep = sentence_with_link_format.search(/\|/);

        let link_text = sentence_with_link_format.substring(link_sep+1, link_end);
        let link_title = sentence_with_link_format.substring(link_start + 2, link_sep);
        let link_address = GLOBLE_WIKI_ADDRESS + link_title.replace(' ','_');
        let link_element = <a href={link_address} title={link_title} target="_blank" rel="noopener noreferrer"> {link_text} </a>;

        ret_elements.push(sentence_with_link_format.substring(0,link_start));
        ret_elements.push(link_element);
        sentence_with_link_format = sentence_with_link_format.substring(link_end+2);
    }
    
    return ret_elements
}

/**
 * 
 * @param {string} props containing a text field which is the sentence evidence in latest model
 * @returns the element that represents an evidence sentence
 */
const RenderSentence = (evidence_sentence) => {
    // console.log(evidence_sentence)
    let ret_elements = sanitiseLinks(evidence_sentence);
    return (<p>{ret_elements.flat(1)}</p>)
}

const sanitiseLinksForCell = (cell_value) => {
    let sentence_with_link_format = cell_value;
    // console.log(sentence_with_link_format)

    let ret_elements = []
    while (true){
        let link_start = sentence_with_link_format.search(/\[\[/);
        if (link_start === -1){
             ret_elements.push(sentence_with_link_format);
            break
        }
        let link_end = sentence_with_link_format.search(/\]\]/);
        let link_sep = sentence_with_link_format.substring(link_start,link_end).search(/\|/);
        
        let link_text = link_sep===-1? sentence_with_link_format.substring(link_start+2, link_end): sentence_with_link_format.substring(link_sep+link_start+1, link_end);
        let link_title = link_sep===-1? link_text: sentence_with_link_format.substring(link_start + 2, link_sep);
        let link_address = GLOBLE_WIKI_ADDRESS + link_title.replace(' ','_');
        let link_element = <a href={link_address} title={link_title} target="_blank" rel="noopener noreferrer"> {link_text} </a>;

        ret_elements.push(sentence_with_link_format.substring(0,link_start));
        ret_elements.push(link_element);
        sentence_with_link_format = sentence_with_link_format.substring(link_end+2);
    }
    
    return ret_elements
}

const RenderCell = (cell, ind, selected_ids) => {
    let selected = selected_ids.toString().includes(ind);
    // console.log(selected)
    let cell_content = sanitiseLinksForCell(cell['value']);
    if(cell_content[0].startsWith('.mw-parser-output')){
        cell_content = '';
    }
    else if (cell['is_header']){
        return (
            <th
            colSpan={cell['column_span']} 
            rowSpan={cell['row_span']} 
            className = {selected ? 'highlighted-cell' : 'normal-cell'}>
                {cell_content.flat(1)}
            </th>
        )
    } else {
        return (
            <td key={cell['id']}
            colSpan={cell['column_span']} 
            rowSpan={cell['row_span']} 
            className = {selected ? 'highlighted-cell' : 'normal-cell'}>
                {cell_content.flat(1)}
            </td>
        )
    }
}

const RenderRow = (row, ind, selected_cell_ids) => {
    let selected_cells_ids_in_row = selected_cell_ids.filter(
        l => l[0]==ind
    );
    let selected_cells_col_ids_in_row = selected_cells_ids_in_row.map(l => l[1])
    let row_id = row[0]['id'].split('_').slice(0,-1).join('_')
    
    return(
        <tr key={row_id}>
            {    
                row.map(
                    (cell,cell_ind) => RenderCell(cell, cell_ind, selected_cells_col_ids_in_row)
                )
            }
        </tr>
    )
}

// Render a single table
// selected cells may not be in the table
const RenderTable = (table, selected_cells) => {
    let table_content = table['content']
    let table_id = table['id'];
    let table_title = table_id.split('_')[0];
    let table_index = table_id.split('_')[2];

    let local_selected_cells = selected_cells.filter(
        id => id.split('_')[0]===table_title && id.split('_')[2]===table_index);
    let local_selected_cell_ids = local_selected_cells.map(
        s => [s.split('_')[3],s.split('_')[4]]);

    return (
        <><div className='table_title'>{table_title}</div><table>
            <tbody>
                {table_content.map(
                    (row, row_ind) => RenderRow(row, row_ind, local_selected_cell_ids)
                )}
            </tbody>
        </table></>
    )
}


const RenderList = (list, selected_list_items) => {
    let list_content = Object.entries(list['content']);
    let list_id = list['id'];
    let list_title = list_id.split('_')[0];
    let list_index = list_id.split('_')[2];

    // let local_selected_items = selected_list_items.filter(
    //     id => id.split('_')[0]===list_title && id.split('_')[2]===list_index);
    // let local_selected_item_ids = local_selected_items.map(
    //     s => [s.split('_')[3],s.split('_')[4]]);

    // console.log(local_selected_item_ids)
    console.log(list)
    console.log(selected_list_items)
    // let ret_elements = sanitiseLinks(evidence_list);
    return (
        <>
          <div className='table_title'>{list_title}</div>
          <ul>
            {list_content.map(list_item => (
              <li key={list_item[0]} className = {selected_list_items.toString().includes(list_item[0]) ? 'highlighted' : 'normal'} >
                {sanitiseLinks(list_item[1]).flat(1)}
              </li>
            ))}
          </ul>
        </>
      );

}

/**
 * Render the result verdict and evidence to page elements
 * @param {*} props 
 * @returns page elements
 */
export const RenderResultFEVEROUS = (props) => {
    let result_json = props.result;
    let waiting = props.waiting;

    if ((props.result === "" && !waiting) || (props.result != "" && 'reporting_source' in props.result)){
        return (
            <div></div>
        )
    }
    else if (waiting){
        //todo: possible add a counter
        return (
            <div>Waiting for result...</div>
        )
    }
    else {
        console.log(result_json);

        let verdict = result_json['verdict'];
        let evidence_sentences = result_json['sentence_evidence'];
        let evidence_tables = result_json['table_evidence'];
        let selected_cells = result_json['selected_cells'];
        let selected_list_items = result_json['selected_list_items'];
        let evidence_lists = result_json['list_evidence'];
        let claim = result_json['claim'];

        let potential_scalar_implicature;
        let generated_questions;
        let generated_answers_num;
        let expected_answer_num ;
        let generated_explanations_num;
        let chunk_questions_comp;
        let chunk_arguments;
        let chunk_polarity;
        let generated_answer_comp;
        let explanation_comp;
        let subclaims;

        if (Array.isArray(result_json['subclaims'])) {
            subclaims = result_json['subclaims'].join('\n');
        }

        if (Array.isArray(result_json['chunk_questions'])) {
            generated_questions = result_json['chunk_questions'].join('\n');
        }
        if (Array.isArray(result_json['generated_answers_num'])) {
            generated_answers_num = result_json['generated_answers_num'].join('\n');
        }
        if (Array.isArray(result_json['chunk_answers'])) {
            expected_answer_num = result_json['chunk_answers'].join('\n');
        }
        if (Array.isArray(result_json['explanation_num'])) {
            generated_explanations_num = result_json['explanation_num'].join('\n');
        }

        if (Array.isArray(result_json['chunk_questions_comp'])) {
            chunk_questions_comp = result_json['chunk_questions_comp'].join('\n');
        }
        if (Array.isArray(result_json['chunk_arguments'])) {
            chunk_arguments = result_json['chunk_arguments'].join('\n');
        }
        if (Array.isArray(result_json['chunk_polarity'])) {
            chunk_polarity = result_json['chunk_polarity'].join('\n');
        }
        if (Array.isArray(result_json['generated_answer_comp'])) {
            generated_answer_comp = result_json['generated_answer_comp'].join('\n');
        }
        if (Array.isArray(result_json['explanation_comp'])) {
            explanation_comp = result_json['explanation_comp'].join('\n');
        }
        if (Array.isArray(result_json['potential_scalar_implicature'])) {
            potential_scalar_implicature = result_json['potential_scalar_implicature'].join('\n');
        }

        // let generated_answers_num = result_json['generated_answers_num'].join('\n');;
        // let expected_answer_num = result_json['chunk_answers'].join('\n');;
        // let generated_explanations_num = result_json["chunk_explanations_num"].join('\n');;
    
        return(
            <>
            <div className='titles'>Potential Scalar implicature: </div>
            <div>{potential_scalar_implicature}</div>
            <div className='titles'>Claim: </div>
            <div>{claim}</div>
            <div className='titles'>Verdict: </div>
            <div className='verdict'>{verdict}</div>
            <div className='titles'>Text Evidence:</div>
            {
                evidence_sentences.map(e => {
                    // not sure if need to add div
                    return (
                        <div key={e}>
                            {RenderSentence(e)}
                        </div>
                    )
                })
            }
            {/* {RenderSentence(evidence_sentences[0])} */}
            {/* todo: test on table rendering is still under testing */}
            <div className='titles'>Tabular evidence:</div>
            {
                evidence_tables.map(table =>{
                    return (
                        <div key={"tab_" + table['id']}>
                            {RenderTable(table, selected_cells)}
                        </div>
                    )
                }
                )
            }
            <div className='titles'>List evidence:</div>
            {
                evidence_lists.map(list =>{
                    return (
                        <div key={"list+" + list['id']}>
                            {RenderList(list, selected_list_items)}
                        </div>
                    )
                }
                )
            }
            <div>Subclaims: {subclaims}</div>
            <br></br>
            <div>Questions: {generated_questions}</div>
            <br></br>
            <div>Expected Answers: {expected_answer_num}</div>
            <br></br>
            <div>Generated Answers: {generated_answers_num}</div>
            <br></br>
            <div>Generated Explanation: {generated_explanations_num}</div>
            <br></br>
            <br></br>
            <div>Comparative Questions: {chunk_questions_comp}</div>
            <br></br>
            <div>Arguments: {chunk_arguments}</div>
            <br></br>
            <div>Polarity: {chunk_polarity}</div>
            <br></br>
            <div>Generated Answers: {generated_answer_comp}</div>
            <br></br>
            <div>Generated explanation: {explanation_comp}</div>

            
            </>
        ) 
    }    

}